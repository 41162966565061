import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwk keyset`}</strong>{` -- add, remove, and find JWKs in JWK Sets`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwk keyset <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwk set`}</strong>{` command group provides facilities for managing and
inspecting JWK Sets. A is a JSON object that represents a set of JWKs. They
are defined in RFC7517.`}</p>
    <p>{`A JWK Set is simply a JSON object with a "keys" member whose value is an array
of JWKs. Additional members are allowed in the object. They will be preserved
by this tool, but otherwise ignored. Duplicate member names are not allowed.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jwk`}</strong>{`.`}</p>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "add/"
              }}>{`add`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a JWK to a JWK Set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "remove/"
              }}>{`remove`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a JWK from a JWK Set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "list/"
              }}>{`list`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`key IDs of JWKs in a JWK Set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "find/"
              }}>{`find`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`a JWK in a JWK Set`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      